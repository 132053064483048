import React from 'react';
import styled from 'styled-components';
import { NAV_LINKS } from '../constants';

import NavItem from './NavItem';

const StyledNavItemGroup = styled.div`
@media (max-width: 716px) {
  display: none;
}

  flex-direction: row;
  margin-right: auto;
  margin-top: auto;
  height: 24px;
  background: ${props => props.theme.primary};
`;

const StyledNavItemGroupDropdwown = styled.div`
@media (min-width: 716px) {
  display: none;
} 
position: absolute:
top:0;
bottom: 0;
`;

const StyledButton = styled.a`
    font-size: 20px;
    font-family: 'Sarpanch',sans-serif;
    font-weight: 900;
    color: var(--wmuc-red);
    text-shadow: -0.03em 0.03em 0 #0f0f0f;
    :hover{
        text-decoration: none;
        text-shadow: -0.05em 0.05em 0 #0f0f0f;
    }
`

const Button = () => {
    return (<StyledButton type='button' href="https://giving.umd.edu/giving/Fund.php?name=wmuc-support-fund">
            DONATE!
          </StyledButton>)
};

const NavItemGroup = ({theme}) => {
  const navItems = NAV_LINKS.map(link => (
    <NavItem 
      theme={theme}
      key={link.label}
      href={link.href}
      label={link.label}
      links={link.links}
      component={link.component}
    />
   
  ));
  return (
    <>
    <StyledNavItemGroup className="navbar-nav" theme={theme}>
      {navItems}
      <li><Button></Button></li>
    </StyledNavItemGroup>
    <StyledNavItemGroupDropdwown className="navbar-nav" theme={theme}>
      {navItems}
      <li>
      <Button></Button>
      </li>
    </StyledNavItemGroupDropdwown>
    </>
  );
}


export default NavItemGroup;
